// Import Core Vars/Mixins
@import "core/variables";
@import "core/mixins";

// Bootstrap
@import "/node_modules/bootstrap/scss/bootstrap";

// Core Components
@import "core/recaptcha";
@import "core/buttons";
@import "core/checkboxes";
@import "dashboard/cards";
@import "core/input-group";
@import "core/nav";
@import "core/images";
@import "dashboard/navbar";
@import "dashboard/alerts";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/modal";
@import "core/dropdown";
@import "core/headers";
@import "core/progress";
@import "core/ripples";
@import "core/menu";
@import "core/cards/card-rotate";

// Dashboard
@import "dashboard/sidebar-and-main-panel";
@import "dashboard/tables";
@import "dashboard/misc";
@import "dashboard/pages";
@import "dashboard/fileupload";
@import "dashboard/responsive";
@import "dashboard/custom";
@import "dashboard/chip";
@import "dashboard/carousel";
@import "dashboard/dialogs";
@import "dashboard/chart";
@import "dashboard/recaptcha";
@import "dashboard/qr-code";
@import "dashboard/date-picker";

// Views
@import "shared/charging-station";
@import "shared/connector-cell";

.navbar-nav {
  mat-icon {
    color: $brand-primary;
  }
}

