.wrapper {
  &:after {
    display: table;
    clear: both;
    content: " ";
  }

  &.wrapper-full-page {
    //height: auto;
    min-height: 100vh;
  }
}

.full-page {

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    background-color: rgba(0, 0, 0, .5);
  }

  &[data-image]:after {
    opacity: .8;
  }

  > .content,
  > .footer {
    position: relative;
    z-index: 4;
  }

  > .content {
    min-height: calc(100vh - 80px);
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

  }

  .footer nav > ul a:not(.btn),
  .footer,
  .footer .copyright a {
    color: $brand-text;
  }

}

.login-page,
.lock-page {
  > .content {
    padding-top: 18vh;
  }
  .page-header {
    min-height: 100vh;
    height: auto;
    display: inherit;

    .container {
      padding-top: 10vh !important;
      opacity: 0.9;
    }

    @media(min-height:1000px){
      .container {
        padding-top: 20vh !important;
      }

    }

  }


}

.eula-page {
  overflow: auto;
  height: 90vh;
  padding-top: 0 !important;
  color: $brand-background;
}

.login-page {

  .main-content{
    box-shadow: 0 5px 5px rgba(0,0,0,.4);
    margin: 5em auto;
      border-radius: 10px;
  }

  .company_info{
    background-color: #7f3f99;
    display: flex;
    flex-direction: column;
    justify-content: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
  }

  @media screen and (max-width: 640px) {
    .main-content{width: 90% !important;}
    .company_info{
      display: none;
    }
      .auth_form{
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
    }
  }
  @media screen and (min-width: 642px) and (max-width:800px){
    .main-content{width: 80% !important;}
      .company_info{
          padding-top: 10px ;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
    }
      .auth_form{
          border-top-right-radius: 0px !important;
          border-top-left-radius: 0px !important;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
    }
  }

  @media screen and (min-width:800px){
    .main-content{width: 80% !important;}
  }
  .card-login {
    @include transform-translate-y(0);
    @extend .animation-transition-general;
  }

  .title-auth{
    color:#7f3f99;
  }

  .auth_form{
    background-color: #fff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
  }

  .auth-btn{
    transition: all .5s ease;
    width: 70%;
    color:#7f3f99;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #7f3f99;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  .auth-btn:hover, .auth-btn:focus{
    background-color: #7f3f99;
    color:#fff !important;
  }

  .forget-password {
    float: right;
  }
}

.auth-layout {
  .logo-auth {
    height: 70px;
    background: white;
    padding: 5px;
    border-radius: 6px;
    opacity: 0.9;
    box-shadow: 0 5px 5px rgb(0 0 0 / 40%);
  }

  .version-auth{
    text-align: center;
    font-weight: 600;
  }
}

.lock-page {

  .card-profile {
    width: 240px;
    margin: 60px auto 0;
    color: $brand-text;
    left: 0;
    right: 0;
    display: block;

    @include transform-translate-y(0);
    @extend .animation-transition-general;

    &.card-hidden {
      opacity: 0;
      @include transform-translate-y(-60px);
    }

    .card-avatar {
      max-width: 90px;
      max-height: 90px;
      margin-top: -45px;
    }

    .card-footer {
      border: none;
    }

    .form-group {
      text-align: left;
      margin-top: 15px;
    }

    &.with-animation {
      @include transition(300ms, $transition-ease-in);
    }

    .card-body + .card-footer {
      padding: 0.9375rem 1.875rem;
      margin: 0;
    }
  }

  .footer {
    z-index: 1;
    color: $brand-text;

    .copyright {
      a {
        color: $brand-text;
        font-weight: 500;

        &:hover {
          color: $brand-text;
        }
      }
    }
  }

}

.register-page {

  .container {
    position: relative;
    z-index: 3;
  }

  .footer {
    color: $brand-text;

    .container {
      padding: 0;
    }
    .copyright {
      a {
        color: $brand-text;
        font-weight: 500;

        &:hover {
          color: $brand-text;
        }
      }
    }
  }
}

.dashboard-page {
  .card-background-blue {
    background-image: url(../../img/BG1.png);
  }

  .card-background-purple {
    background-image: url(../../img/BG2.png);
  }

  .card-background-red {
    background-image: url(../../img/BG3.png);
  }

  .card-background-yellow {
    background-image: url(../../img/BG4.png);
  }
  .card-stat{
    background-size: cover;
    height: 100px !important;
    padding: 20px;
    color: #fff;
  }
  .card-title {
    background: #e3e3e3f5;
    color: #000000b3;
    text-align: center;
    padding: 10px;
    font-weight: 500;
  }
  .icon {
    font-size: 45px;
  }

  .value {
    font-weight: 600;
    font-size: 28px;
  }
}

.off-canvas-sidebar {
  .navbar-toggler .navbar-toggler-icon {
    background-color: $brand-text !important;
  }

  .navbar-collapse {
    .nav {
      > li > a,
      > li > a:hover {
        color: $brand-text;
        margin: 0 15px;
      }

      > li > a:focus,
      > li > a:hover {
        background: rgba(200, 200, 200, 0.2);
      }
    }
    &:after {
      background-color: #282828;
    }
  }
}

@media screen and (max-width: 991px) {
  .off-canvas-sidebar {
    .page-header {
      > .container {
        padding-top: 70px;
        padding-bottom: 200px;
      }
    }
  }
}

.login-page {
  .footer {
    .container {
      padding: 0;
    }
    .copyright,
    a {
      color: $brand-text;
      font-weight: 500;
    }
  }
}
