@import "../core/misc";

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mat-toolbar-settings {
  padding: 8px 8px 0 8px;
}

.component-item {
  height: 60px;
}
.mat-form-field, .mat-select-content, .mat-select, .mat-option {
  font-size: $font-size-sm !important;
  width: 100%;
}

.mat-form-field-infix {
  border-top-width: 10px;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: transparent;
  overflow: hidden;
  z-index: 666666666666;
}

.spinner div:first-child {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  box-shadow: 0 3px 3px 0 $brand-primary;
  transform: translate3d(0, 0, 0);
  animation: spin 2s linear infinite;
}

.spinner div:first-child:after,
.spinner div:first-child:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.spinner div:first-child:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  box-shadow: 0 3px 3px 0 $brand-secondary;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

.spinner div:first-child:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  box-shadow: 0 3px 3px 0 $brand-grey;
  animation: spin 1.5s linear infinite;
}

a {
  color: $link-color;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover,
    &:focus {
      color: darken($brand-info, 5%);
    }
  }

  & .material-icons {
    vertical-align: middle;
  }
}

a[data-toggle="collapse"][aria-expanded="true"] .caret,
.dropdown.open .caret,
.dropup.open .caret,
.btn-group.bootstrap-select.open .caret {
  @include rotate-180();
}

.caret,
.bootstrap-tagsinput .tag,
.sidebar a,
.bootstrap-tagsinput [data-role="remove"] {
  @include transition-all($fast-transition-time, $transition-ease-in);
}

.new-spinner {
  position: absolute;
  height: 200px;
  width: 200px;
  background-image: url("../../img/electric-vehicle-charging-station.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32.5px 0;
  margin: 0;
  overflow: hidden;
  width: 163px;
}

/**
* ==============================================
* Dot Pulse
* ==============================================
*/
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #7f3f98;
  color: #7f3f98;
  box-shadow: 9999px 0 0 -5px #7f3f98;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #7f3f98;
  color: #7f3f98;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #7f3f98;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #7f3f98;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #7f3f98;
  }

  30% {
    box-shadow: 9984px 0 0 2px #7f3f98;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #7f3f98;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #7f3f98;
  }

  30% {
    box-shadow: 9999px 0 0 2px #7f3f98;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #7f3f98;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #7f3f98;
  }

  30% {
    box-shadow: 10014px 0 0 2px #7f3f98;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #7f3f98;
  }
}

/**
* ==============================================
* Dot Collision
* ==============================================
*/
.dot-collision {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #7f3f98;
  color: #7f3f98;
}

.dot-collision::before,
.dot-collision::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision::before {
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #7f3f98;
  color: #7f3f98;
  animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision::after {
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #7f3f98;
  color: #7f3f98;
  animation: dotCollisionAfter 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dotCollisionBefore {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-15px);
  }
}

@keyframes dotCollisionAfter {
  0%,
  50%,
  75%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }
}

/**
* ==============================================
* Experiment-Gooey Effect
* Dot Shuttle
* ==============================================
*/
.dot-shuttle {
  position: relative;
  left: -15px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  margin: -1px 0;
  filter: blur(2px);
}

.dot-shuttle::before,
.dot-shuttle::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: black;
  color: transparent;
  filter: blur(2px);
}

.dot-shuttle::before {
  left: 15px;
  animation: dotShuttle 2s infinite ease-out;
}

.dot-shuttle::after {
  left: 30px;
}

@keyframes dotShuttle {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-45px);
  }
  75% {
    transform: translateX(45px);
  }
}
