@use "sass:map";

$width-height-levels: 20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95;

.mat-dialog-content {
  padding: 4px 24px;
}

.dialog-header {
  display: flex;
  flex-direction: row;
}

.dialog-title {
  align-self: flex-start;
  margin: auto 0 10px 0;
  flex-grow: 1;
}

.dialog-icon-right {
  align-self: flex-end;
  text-align: end;
  margin: auto 0 5px 0 !important;
}

.mat-dialog-actions {
  padding: 15px 0 !important;
}

.card .mat-dialog-actions {
  padding: 0 !important;
}

.mat-dialog-container {
  .main-content {
    height: 100%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    .card {
      .mat-tab-group {
        height: 100%;
        .card-body{
          height: 100%;
        }
      }
    }
  }
}

@each $prop, $unit in (width: vw, height: vh) { // for each width and height
  @each $breakpoint in map-keys($grid-breakpoints) { // Loop over breakpoints xs, sm, md, lg, xl, xxl, 3xl
    @media (min-#{$prop}: map.get($grid-breakpoints, $breakpoint)) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints); // Create -lg
      @each $size in $width-height-levels { // for each 20 -> 95 will short the list once every dialog is done so I know which one i really need
        .#{$unit}#{$infix}-#{$size} { #{$prop}: #{$size}#{$unit} !important; } // -> .vh-lg-50 { height: 50 !important}
      }
    }
  }
}
